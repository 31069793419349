import { json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { StrapiRepository } from "~/openapi/strapiRepository";
import { ExpandingImages } from "~/components/homepage/expanding-images";
import NumberTicker from "~/components/ui/number-ticker";
import BlurFade from "~/components/ui/blur-fade";
import { Button } from "~/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useIsFirstRender } from "@uidotdev/usehooks";

export const loader = async () => {
  const strapiRepository = new StrapiRepository();
  const { getHomepage } = strapiRepository;
  const { data: homepage } = await getHomepage();

  return json({
    homepage,
  });
};

const IMAGES_CHANGE_FREQUENCY = 8000; //in milliseconds

export default function Index() {
  const data = useLoaderData<typeof loader>();
  const hero_section = data.homepage?.data?.hero_section;
  if (!hero_section) throw new Error("Homepage data is not available");

  const { getImageUrl } = new StrapiRepository();

  const isFirstRender = useIsFirstRender();

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  useEffect(() => {
    if (!hero_section.photos?.length) return;

    const interval = setInterval(() => {
      setCurrentPhotoIndex((prev) => {
        return prev === (hero_section.photos?.length ?? 0) - 1 ? 0 : prev + 1;
      });
    }, IMAGES_CHANGE_FREQUENCY);

    return () => clearInterval(interval);
  }, [hero_section.photos?.length]);

  return (
    <div className={"-top-navbar-height relative"}>
      {/*Hero section*/}
      <div
        className={
          "relative flex h-svh w-svw flex-col items-center justify-center text-balance bg-foreground/80 text-center text-background"
        }
      >
        {hero_section.photos && (
          <AnimatePresence>
            <motion.img
              key={currentPhotoIndex}
              src={getImageUrl(hero_section.photos[currentPhotoIndex])}
              alt={hero_section.photos[currentPhotoIndex].alternativeText}
              initial={
                isFirstRender ? { scale: 1.05 } : { opacity: 0, scale: 1.05 }
              }
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                scale: {
                  duration: IMAGES_CHANGE_FREQUENCY / 1000,
                  ease: "linear",
                },
              }} // Adjust duration as needed
              className={"absolute h-full w-full object-cover brightness-[30%]"}
            />
          </AnimatePresence>
        )}
        <div className={"container z-10 mx-auto flex flex-col gap-8 px-8"}>
          <BlurFade delay={0.1} inView>
            <h1
              className={
                "text-4xl font-bold leading-10 tracking-wide md:text-5xl lg:text-6xl xl:text-7xl"
              }
            >
              {hero_section.title}
            </h1>
          </BlurFade>
          <BlurFade delay={0.3} inView>
            <p className={"text-lg md:text-xl lg:text-2xl xl:text-3xl"}>
              {hero_section.description}
            </p>
          </BlurFade>
          <div className={"flex justify-center gap-4"}>
            {hero_section.cta_buttons?.map((button, index) => {
              if (!button.link) return null;
              return (
                <BlurFade key={button.id} delay={0.5 + 0.2 * index} inView>
                  <Button variant={button.variant} asChild>
                    <Link to={button.link}>{button.text}</Link>
                  </Button>
                </BlurFade>
              );
            })}
          </div>
        </div>
      </div>

      <NumberTicker value={426} />
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <div>a</div>
      <ExpandingImages />
    </div>
  );
}
