import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";

export const ExpandingImages = () => {
  const images = [
    {
      src: "https://via.assets.so/game.png?id=1&q=95&w=360&h=360",
      alt: "Abstract art 1",
    },
    {
      src: "https://via.assets.so/game.png?id=2&q=95&w=360&h=360",
      alt: "Abstract art 2",
    },
    {
      src: "https://via.assets.so/game.png?id=3&q=95&w=360&h=360",
      alt: "Abstract art 3",
    },
    {
      src: "https://via.assets.so/game.png?id=4&q=95&w=360&h=360",
      alt: "Abstract art 4",
    },
    {
      src: "https://via.assets.so/game.png?id=5&q=95&w=360&h=360",
      alt: "Abstract art 5",
    },
  ];

  return (
    <div className="flex w-full">
      {images.map((image, index) => (
        <div
          key={index}
          className={cn(
            "group relative transition-all duration-300 ease-in-out hover:w-1/2",
            {
              "w-full": images.length === 1,
              "w-1/2": images.length === 2,
              "w-1/3": images.length === 3,
              "w-1/4": images.length === 4,
              "w-1/5": images.length === 5,
              "w-1/6": images.length === 6,
              "w-1/7": images.length === 7,
              "w-1/8": images.length === 8,
              "w-1/9": images.length === 9,
              "w-1/10": images.length === 10,
            },
          )}
        >
          {/*<img*/}
          {/*  src={image.src}*/}
          {/*  alt={image.alt}*/}
          {/*  className="h-80 w-full object-cover transition-all duration-300 ease-in-out"*/}
          {/*/>*/}
          <div
            className="flex h-80 w-full flex-col justify-center bg-opacity-20 transition-all duration-300 ease-in-out group-hover:bg-opacity-0"
            style={{
              background: `url(${image.src}) no-repeat center`,
              backgroundSize: "cover",
            }}
          >
            <div
              className={
                "flex flex-col items-center justify-center gap-3 text-2xl text-background opacity-0 transition-all duration-300 group-hover:opacity-100"
              }
            >
              <span>Rodinné domy</span>
              <Button
                variant={"outline"}
                className={
                  "w-min bg-transparent text-white hover:bg-transparent hover:text-white"
                }
              >
                Zobrazit více
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
